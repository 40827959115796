import { ACCESS_TOKEN, IMPERSONATE_ACCESS_TOKEN } from '@utils/auth-tokens';
import Cookies from 'cookies';
import { GetServerSidePropsContext } from 'next';
import LoginPage from '@screens/LoginPage';

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const cookies = new Cookies(ctx.req, ctx.res);
  const authToken = cookies.get(ACCESS_TOKEN);

  return {
    props: { isLoggedIn: !!authToken },
  };
};

export default ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  return <LoginPage isLoggedIn={isLoggedIn} />;
};
